<template>
  <v-dialog :value="value" persistent :width="layoutCreated ? 1000 : 500">
    <v-card color="fill">
      <v-card-title class="accent--text font-weight-light">
        {{isExistingLayout ? 'Layout Builder' : 'Create Layout'}}
        <v-spacer/>
        <v-menu v-if="isExistingLayout" offset-y offset-x left bottom :nudge-right="5" :nudge-top="5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-bind="attrs" v-on="on" icon color="delete" class="white--text">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-6">
            <v-row justify="center" class="py-4">
              <span>Delete Layout?</span>
            </v-row>
            <v-row>
              <v-btn text :class="body">Cancel</v-btn>
              <v-btn :class="body" class="primary white--text ml-2" @click="deleteLayout">Confirm</v-btn>
            </v-row>
          </v-card>
        </v-menu>
      </v-card-title>
      <v-container class="ma-0 pa-4" v-resize="onResize">
        <v-form ref="form">
          <v-row align="center" dense style="height:50px;">
            <v-col :cols="layoutCreated ? 5 : 12">
              <v-text-field :class="body" autofocus background-color="#fff" dense outlined label="Layout Name"
                v-model="layout.ad_name" autocomplete="off" :rules="[v => !!v ||  'Layout Name Required']">
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-container v-if="layoutCreated" class="pa-1" fluid>
        <v-row dense>
          <v-col md="3" lg="3" xl="3" class="pt-1 pb-0 px-0">
            <v-toolbar height="32" flat class="accent white--text">
              <v-toolbar-title class="text-caption font-weight-bold">Pages</v-toolbar-title>
            </v-toolbar>
            <v-tabs vertical background-color="fill" color="primary" light class="pt-0"
              hide-slider :style="tabsHeight" v-model="tab">
              <v-tab v-for="(page, index) in pages" :key="index" @change="adPage=page"
                class="text-capitalize font-weight-normal text-body-2">
                <span :class="adPage===page ? 'font-weight-bold' : 'font-weight-normal'"> {{ page.name }}</span>
                <v-spacer/>
                <v-icon v-if="adPage===page">mdi-chevron-right</v-icon>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col class="mt-1 pt-0 px-0" :style="colBorder">
            <v-data-table disabled :headers="headers" :items-per-page=-1 :items.sync="pagePositions" :search="search"
              :loading="loading" v-model="selected" item-key="id" :class="table" show-select dense fixed-header
              :height="tableHeight" hide-default-footer class="pa-0 ma-0" :item-class="itemRowBackground">

              <template #[`header.data-table-select`]="{ on, props }">
                <v-simple-checkbox :ripple="false" v-on="on" v-bind="props" dark />
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-divider v-if="isExistingLayout" />
      <v-toolbar flat color="fill">
        <v-spacer />
        <v-btn :class="body" text @click="$emit('update:value', false)">
          {{isExistingLayout ? 'Close': 'Cancel'}}
        </v-btn>
        <v-btn v-if="!isExistingLayout" :class="body" class="save white--text ml-2"
          @click="isExistingLayout ? updateLayout() : createLayout()">
          {{isExistingLayout ? 'Update' : 'Save'}}
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { debounce } from 'lodash'

export default {
  name: 'LayoutBuilder',

  props: [
    'value',
    'selectedLayout',
    'defaultParty'
  ],

  mixins: [displayHelpers],

  data: () => ({
    adPage: {},
    existingFeatures: [],
    loading: false,
    page: {},
    pagePositions: [],
    pages: [],
    search: '',
    selected: [],
    tab: 0,
    tableHeight: '',
    layout: {},
    layoutFeatures: []
  }),

  computed: {
    isExistingLayout () {
      return !this._.isEmpty(this.layout.id)
    },

    layoutCreated () {
      return !this._.isEmpty(this.layout.id)
    },

    layoutName () {
      return this.layout.ad_name
    },

    headers () {
      return [
        { sortable: false, class: 'accent white--text', value: 'data-table-select' },
        { text: 'Feature Position', sortable: true, filterable: true, class: 'accent white--text', value: 'name' }
      ]
    },

    tabsHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl': return 'height:610px;'
        case 'lg': return 'height:500px;'
        case 'md': return 'height:485px;'
        default: return '5em';
      }
    },

    colBorder () {
      return `
        border:1px solid #d7d7d7;
        border-bottom: none;
        border-top: none;
      `
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.tableHeight = window.innerHeight - 299
          this.getAdPages()
        }
      }
    },

    selectedLayout: {
      immediate: true,
      handler () {
        if (!this._.isEmpty(this.selectedLayout)) {
          this.layout = this._.cloneDeep(this.selectedLayout)
        }
      }
    },

    layoutName: debounce(function () {
      if (this.isExistingLayout) {
        this.updateLayout()
      }
    }, 500),

    selected: {
      deep: true,
      handler (newValue, oldValue) {
        if (newValue.length !== oldValue.length) {
          this.saveFeatures(this.adPage)
        }
      }
    },

    adPage: {
      handler (newValue, oldValue) {
        // if (this.isExistingTemplate) this.getExistingFeatures()
        if (!this._.isEmpty(oldValue) && !this._.isEmpty(newValue))  {
          // this.saveFeatures(oldValue)
          this.getPagePositions()
        }
      }
    }
  },
  
  methods: {
    onResize () {
      this.tableHeight = window.innerHeight - 299
    },

    itemRowBackground (item) {
      return this.pagePositions.indexOf(item) % 2 !== 1 ? 'grey lighten-2' : ''
    },

    async getAdPages () {
      await this.$KeyValues.getAdPages()
        .then(async (res) => {
          this.pages = res.data
          this.adPage = this.pages.find(page => page.constant === 'PAGE_1')
          await this.getPagePositions()
        })
    },

    async getPagePositions () {
      this.loading = true
      await this.$KeyValues.getPagePositions(this.adPage.constant)
        .then(res => {
          this.pagePositions = res.data
          this.loading = false
        })
      if (this.isExistingLayout) {
        this.getExistingFeatures()
      } 
    },

    async getExistingFeatures () {
      await this.$Features.getAdPageFeatures(this.layout.id, this.adPage.id)
        .then(res => {
          this.existingFeatures = res.data.reduce((acc, result) => {
            result = {
              id: result.position.id,
              feature_id: result.id
            }
            acc.push(result)
            return acc
          }, [])

          const existingDifference = this._.differenceBy(this.pagePositions, this.existingFeatures, 'id')
          this.selected = this._.difference(this.pagePositions, existingDifference)
          this.loading = false
        })
    },

    async saveFeatures (prevPage) {
      let feature = {
        ad_id: this.layout.id,
        feature_text: '',
        non_promo_items: [],
        notes: '',
        page: prevPage,
        position: {},
        promo_item_group_ids: [],
        count:0,
        ad_retail:0.00,
        brand_combo:''
      }

      let addFeatures = this._.differenceBy(this.selected, this.existingFeatures, 'id')
      let removeFeatures = this._.differenceBy(this.existingFeatures, this.selected, 'id')

      let addPromises = []
      let removePromises = []
    
      if (addFeatures.length) {
        addFeatures.map(o => {
          feature.position.id = o.id
          addPromises.push(this.$Features.createAdFeature(feature))
        })
      }
      
      if (removeFeatures.length) {
        removeFeatures.map(o => {
          removePromises.push(this.$Features.deleteAdFeature(o.feature_id))
        })
      }

      await Promise.allSettled(addPromises)
      await Promise.allSettled(removePromises)
      this.getExistingFeatures()
    },

    async getLayout (layoutID) {
      await this.$Layouts.getLayoutbyId(layoutID)
        .then(res => {
          this.layout = res.data
        })
    },

    async createLayout () {
      const valid = this.$refs.form.validate()
      if (valid) {
        const layout = {
          features: [],
          ad_party_id: this.defaultParty.id,
          ad_name: this.layout.ad_name
        }
        await this.$Layouts.createLayout(layout)
        .then(res => {
          this.layout.id = res.data.id
          this.$emit('refresh')
          this.getLayout(this.layout.id)
          this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.layout.ad_name} Created` })
        }).catch(() => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Create Layout` })
        })
      }
    },

    async updateLayout () {
      await this.$Layouts.updateLayout(this.layout.id, this.layout)
        .then(() => {
          this.$emit('refresh')
          this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.layout.ad_name} Updated` })
        }).catch(() => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: 'Failed to Update Layout' })
        })
    },

    async deleteLayout () {
      await this.$Layouts.deleteLayout(this.layout.id)
        .then(() => {
          this.$emit('refresh')
          this.$emit('update:value', false)
          this.$store.dispatch('setSnackbar', { status: 'success', text: `${this.layout.ad_name} Deleted` })
        }).catch(() => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: 'Failed to Delete Layout' })
        })
    }
  }
}
</script>
<style scoped>
  .active {
    font-weight: bold;
    background: #e0e0e0;
    color: #000 !important;
  }
</style>